import React from "react";
// import classNames from "classnames";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));
const Panels = loadable(() => import("@organisms/Panels"));
const Hero = loadable(() => import("@organisms/Hero"));

const panels = [
  {
    heading: "It's Your Ship",
    subheading: "I'm a business partner – when you need one.",
    copy: "<p>Run your business with a clear sense of direction. I work alongside next-generation entrepreneurs to strengthen and grow your business. This is practical, real-world stuff. Set a course for your company and navigate true.</p>",
    icon: "ship",
  },
  {
    heading: "Navigate Leaps of Faith",
    subheading: "I steer you through the details & the big picture.",
    copy: `<p>At any given time, I’m working with a select handful of entrepreneurs. They all share one common trait – a drive to move their company forward. When you run your own gig, it can be a challenge to think beyond the day-to-day.
    </p>
    <p>That’s where I come in. I’m in the trenches with you. We start with the foundation of your business – cash flow, contracts, client relations, systems, marketing, etc. Once the bases are covered, we work to streamline communication with your clients and crew. Lastly, I provide a sounding board for new ideas and innovation around which you can make strategic leaps.</p>
    `,
    icon: "navigate",
    image: "birds",
  },
  {
    heading: "The Voyage",
    subheading: "I act as a Zen Ambassador and Negotiator.",
    copy: "<p>Cultivate clear communication around time and money – with, employees and your awesome clients.Learn everyone’s needs and expectations up front, rather than discovering it along the way. When you know what people’s actions are going to be, you can be more efficient and creative.</p>",
    icon: "voyage",
    inverse: false,
  },
  {
    heading: "Fearless Wayfinder",
    subheading: "I cross-pollinate ideas and solutions.",
    copy: "<p>As an equity-free partner, I can help you turn your entrepreneurial ideas into actions. With multiple clients in different industries, I’m free to be fearless and straight-up, giving you the benefit of my experience.</p>",
    icon: "fearless",
    image: "maps",
  },
  {
    heading: "Fair Winds at Your Back",
    subheading: "I understand your business.",
    copy: `<p>Hailing from a family of successful unconventional entrepreneurs, my eclectic background ranges from competitive figure skating to managing a multi-million dollar financial services company. I earned a Bachelor’s of Fine Arts Degree in Film and Design from the Art Center College of Design in Pasadena.</p>
    <p>I formed The Laughing Phoenix, Inc. in 2005 to help entrepreneurs kick ass.</p>
    <p>My range of experience helps me understand your business and work style. I bring humor to the process. We often forget and take this WAY too seriously.</p>
    `,
    icon: "fairwinds",
    headshot: "lisa_head",
    inverse: false,
  },
  {
    heading: "Ports of Call",
    subheading: "I travel between several worlds.",
    copy: `<p>Running a business isn’t for the faint of heart. I work with entrepreneurs who run their businesses full steam ahead. They refuse to fit into a niche and are continuously pushing boundaries.</p>
    <p>Some of these Kick-Ass Rebels include:</p>
    `,
    logos: [
      {
        name: "Design By Cosmic, Inc.",
        image: "cosmic-logo-vertical-white.png",
        url: "http://designbycosmic.com/",
      },
      {
        name: "Testorff Construction, Inc.",
        image: "logo__testorff.png",
        url: "http://testorffconstruction.com/",
      },
      {
        name: "Atlas Geotechnical, Inc.",
        image: "logo__atlasGeotechnical.png",
        url: "http://atlasgeotechnical.com/",
      },
      {
        name: "Left Coast R&D, Inc.",
        image: "logo__leftCoastRD.png",
      },
      {
        name: "Brokers Apps, Inc.",
        image: "logo__brokersAppsInc.png",
      },
      {
        name: "Lindsay Digital, Inc.",
        image: "logo__lindayDigital.png",
        url: "http://lindsaydigital.com",
      },
      {
        name: "Thieves Co.",
        image: "logo__thieves.png",
      },
      {
        name: "Infrno, Inc.",
        image: "logo__infrno.png",
      },
    ],
  },
  {
    heading: "As the Crow Flies",
    subheading: "I’m located in Santa Cruz, CA.",
    copy: `<p>Mountains. Ocean. World-famous boardwalk. Vampires. A close neighbor to Silicon Valley and San Francisco. It’s an awesome place to live and work.</p>
    <p>Here in the San Francisco Bay Area, we’re fortunate to be in the epicenter of the new business economy. This economy is led by business innovators who are nimble and bold – ready to change when their current methodology no longer serves them or their company.</p>
    <p>I rarely pass up the opportunity to talk to someone interesting. You never know where it will lead. Do you have something really cool happening? Hail me.
    </p>`,
    icon: "crow",
    image: "beach",
  },
];

const meta = {
  title: "Lisa Benson: Nomadic Business Strategist",
  metaTitle: "Lisa Benson: Nomadic Business Strategist",
  metaDescription:
    "Run your business with a clear sense of direction. I work alongside next-generation entrepreneurs to strengthen and grow your business. This is practical, real-world stuff. Set a course for your company and navigate true.",
  metaImage: "https://lisabenson.imgix.net/boat_graded.jpg",
  url: "https://lisabenson.net/business-strategy",
  slug: "business-strategy",
};

const Home = () => {
  return (
    <PageContainer meta={meta}>
      <Hero
        subheading="Nomadic Business Strategist"
        tagline={`<span>
          Bored with what is.
          <br />
          Focused on what can be.
        </span>`}
        image="boat_graded"
        path="/business-strategy"
      />
      <Panels panels={panels} />
    </PageContainer>
  );
};

export default Home;
